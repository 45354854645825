@import "react-quill/dist/quill.snow.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #f6f6f6;
}

html * {
  font-family: "Heebo", "sans-serif" !important;
}

@-moz-keyframes rotate90 {
  100% {
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@-webkit-keyframes rotate90 {
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes rotate90 {
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.MuiAutocomplete-listbox {
  max-height: 300px;
  overflow-y: auto;
}
.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 8px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: #cccdcf;
  border-radius: 10px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
  background-color: #b0b0b0;
}
.scrollBlue::-webkit-scrollbar {
  display: unset !important;
  background: #2e3092;
  width: 4px;
  border-radius: 24px;
}

.show-scrollbar::-webkit-scrollbar {
  width: 8px;
  background: "beige";
}

.show-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  margin: 100px 0 0 0;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #2e3092;
  border-radius: 10px;
}

.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.show-scrollbar::-webkit-scrollbar-thumb:active {
  background: #333;
}

.MuiSlider-rail {
  height: 7px !important;
}

.MuiSlider-track {
  height: 7px !important;
}

.MuiSlider-root {
  color: #2e3092 !important;
}

.MuiOutlinedInput-root fieldset {
  border: none !important;
}

.MuiTextField-root input {
  border: none !important;
}

.Mui-expanded {
  margin: 0px !important;
}

.MuiAccordion-root {
  width: 100% !important;
}

input::-webkit-input-placeholder {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-left: 8px;
  padding-top: 13px;
  padding-bottom: 12px;
  color: var(--medium-200, #b9b9d9);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/*firefox 19+ versions*/
input::-moz-placeholder {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-left: 8px;
  padding-top: 13px;
  padding-bottom: 12px;
  color: var(--medium-200, #b9b9d9);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/*IE  versions*/
input::-ms-placeholder {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-left: 8px;
  padding-top: 13px;
  padding-bottom: 12px;
  color: var(--medium-200, #b9b9d9);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/*Latest modern browsers */

input::placeholder {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-left: 8px;
  padding-top: 13px;
  padding-bottom: 12px;
  color: var(--medium-200, #b9b9d9);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.MuiAccordion-root:before {
  position: relative !important;
}

.MuiAccordionDetails-root {
  padding-top: 12px !important;
}

.rdrCalendarWrapper {
  direction: ltr;
}

.rdrStaticRangeLabel {
  text-align: unset !important;
}

.dots-loader > div {
  animation: dots-loader 0.6s infinite alternate;
}

@keyframes dots-loader {
  to {
    opacity: 0.1;
    transform: translateY(2px);
  }
}

/*!* Editor styling *!*/

/*.ql-toolbar.ql-snow {*/
/*  background-color: #EBECFF;*/
/*  border-radius: 12px 12px 0px 0px;*/
/*  border-color: #9695C7 !important;*/

/*}*/

/*.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {*/
/*  background-color: #FFFFFF;*/
/*}*/

/*.ql-toolbar.ql-snow .ql-header-rtl .ql-picker-label {*/
/*  display: flex;*/
/*  flex-direction: row-reverse;*/
/*}*/

/*.ql-toolbar.ql-snow .ql-font-rtl .ql-picker-label {*/
/*  display: flex;*/
/*  flex-direction: row-reverse;*/
/*}*/

.scroll-container {
  overflow-x: scroll;
  white-space: nowrap;
  /* Prevent text from wrapping */
  padding: 10px;
  display: flex;
}

.react-horizontal-scrolling-menu--arrow-right {
  justify-content: center;
  align-items: center;
}

.react-horizontal-scrolling-menu--arrow-left {
  justify-content: center;
  align-items: center;
}

/*!* new editor*!*/
/*.ql-container.ql-snow {*/
/*  border-radius: 0px 0px 12px 12px;*/
/*}*/

/*.badgeVariable {*/
/*  background-color: rgb(235, 236, 255);*/
/*}*/

/*.editorDiv[dir=rtl] .ql-toolbar.ql-snow .ql-picker-label {*/
/*  display: flex;*/
/*  flex-direction: row-reverse;*/
/*}*/

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  align-items: center;
}

.customInput:hover {
  background-color: #e9ecef;
  width: fit-content;
}

.MuiOutlinedInput-root {
  padding: 0 !important;
  padding-left: 2px !important;
  padding-top: 1px !important;
}

.MuiAutocomplete-input {
  padding: 0 !important;
  padding-left: 20px !important;
  padding-top: 1px !important;
}

.table-sorting:hover {
  background-color: #ebecff;
}

.MuiTabs-flexContainer {
  gap: 25px !important;
}

.MuiTabs-scrollButtons {
  display: flex !important;
  align-items: flex-start !important;
  margin-top: 3px !important;
}

.react-international-phone-flag-emoji {
  display: none;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  margin-left: 4px;
}

.react-international-phone-country-selector-dropdown {
  min-width: 180px !important;
  width: 180px !important;
  position: fixed !important;
  top: var(--react-international-phone-dropdown-left, 40px) !important;
  left: unset !important;
  z-index: 10 !important;
}

:root {
  --react-international-phone-dropdown-left: 20;
}

.MuiTooltip-tooltip {
  background: #ebecff;
  color: #1c1d58;
  font-size: 16px;
  font-weight: 500;
  max-width: 180px;
}

df-messenger {
  z-index: 999;
  position: fixed;
  --df-messenger-font-color: #000;
  --df-messenger-font-family: Google Sans;
  --df-messenger-chat-background: #f3f6fc;
  --df-messenger-message-user-background: #d3e3fd;
  --df-messenger-message-bot-background: #fff;
  bottom: 16px;
  right: 16px;
}

.react-chatbot-kit-chat-container {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #2e3092 !important;
}

.react-chatbot-kit-chat-header {
  background-color: #2e3092 !important;
  color: #fff !important;
}

.background {
  position: absolute;
  z-index: 1;
  right: 0;
}

.background svg {
  color: white;
  opacity: 0.2;
}

.background circle {
  stroke-dashoffset: 0 !important;
}

.foreground {
  position: relative;
  z-index: 2;
}

.foreground svg {
  color: white;
}

.MuiTooltip-popper {
  z-index: 999999;
}

.ant-select-dropdown {
  z-index: 999999;
}

/* Base styles for tree nodes */

.p-treenode-content .p-treenode-icon {
  display: none !important;
  /* Hide the arrow icon */
}

.p-treeselect-panel .p-treeselect-empty-message {
  display: flex;
  align-items: center;
  height: 100%;
  color: #867f7f !important;
  font-family: Heebo;
  font-style: normal;
  text-align: center;
  padding: 4px 10px;
  font-weight: 300;
  text-align: center;
  font-family: Roboto, Arial, sans-serif;
}

.p-treenode.p-tree-empty-message {
  display: flex;
  align-items: center;
  height: 100%;
  color: #867f7f !important;
  font-family: Heebo;
  font-style: normal;
  text-align: center;
  padding: 4px 10px;
  font-weight: 300;
  text-align: center;
  font-family: Roboto, Arial, sans-serif;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  display: none;
  /* Hide the close icon */
}

.p-connected-overlay-enter-done {
  z-index: 1400 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #cccdcf !important;
  border-radius: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0 !important;
}

/* .custom-scrollbar {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scrollbar-width: 10px;
  scrollbar-color: rgba(0, 0, 0, 0.5) #f1f1f1;
} */

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  padding: 5px;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container input {
  padding: 2px;
  align-items: center;
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
  background: var(--Colors-Background-bg-primary, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.drop-zone {
  transition: background-color 0.3s ease;
}

.drop-zone.dragging {
  background-color: #f0f0f0;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background-color: #d5d6e9;
  }

  100% {
    background-color: #abacd3;
  }
}

.rs-anim-fade {
  z-index: 9999999999999999 !important;
}

.rs-picker-input-group {
  height: 42px !important;
}

.rs-input {
  padding-right: 10px !important;
}

.production-floor-gantt-station-row {
  background-color: black;
  position: relative;
  z-index: 300;
}
.react-calendar-timeline .rct-header-root {
  background-color: #fff;
}

.react-calendar-timeline .rct-dateHeader-primary {
  color: #101828;
  border: 0;
}

.time-line-header {
  color: #475467;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.react-calendar-timeline .rct-dateHeader {
  background-color: #fff;
  border: 0;
}
.react-calendar-timeline .rct-calendar-header {
  border: 0;
}
.react-calendar-timeline .rct-sidebar {
  border: 0;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid #eaecf0;
}

.rct-vl {
  border-left: 0 !important;
}
.rct-horizontal-lines > * {
  background-color: inherit !important;
  border: 0 !important;
}
